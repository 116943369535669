import React, { Component } from 'react';
import {
  Container,
  Divider,
  Button,
  Alert,
  Box,
  TextField,
  MenuItem,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

function mapStateToProps(state) {
  return {
    exportCsv: state.exportWithdrawalCsv,
  };
}

const banks = [
  { key: 'bos', value: 'bos', text: 'Bank Ochrony Środowiska' },
  { key: 'ca', value: 'ca', text: 'Crédit Agricole' },
];

class ExportCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      provider: '',
      error: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.exportCsv !== this.props.exportCsv) {
      if (this.props.exportCsv.status === 'ERROR') {
        this.setState({ error: this.props.exportCsv.reason });
      } else {
        this.downloadFile();
      }
    }
  }

  downloadFile() {
    const { content, filename } = this.props.exportCsv;
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.setAttribute('download', filename);
    link.click();
  }

  handleChange(e) {
    this.setState({
      provider: e.target.value,
      error: '',
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.actions.exportCsv(this.state.provider);
  };

  render() {
    return (
      <Container sx={{ marginTop: '50px' }}>
        <Divider> Export withdrawal csv</Divider>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { width: '100%' },
            marginTop: '8px',
          }}
          noValidate
          autoComplete='off'
          onSubmit={e => this.handleSubmit(e)}
        >
          <TextField
            sx={{ marginBottom: '10px' }}
            id='Provider'
            select
            label='Provider'
            name='Provider'
            value={this.state.provider}
            onChange={this.handleChange.bind(this)}
          >
            {banks.map(option => (
              <MenuItem key={option.key} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
          <br />

          <Button
            sx={{ marginBottom: '12px' }}
            variant='contained'
            disableElevation
            type='submit'
            disabled={!this.state.provider}
          >
            Export
          </Button>
        </Box>
        {this.state.error && <Alert severity='error'>{this.state.error}</Alert>}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportCsv);
