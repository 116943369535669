import React from 'react';
import ExportCsv from '../../components/admin/ExportCsv';
import Layout from '../../templates/layout';
import HasUserRole from '../../templates/hasUserRole';

const exportCsv = () => {
  return (
    <Layout >
      <HasUserRole role='admin' isPage>
        <ExportCsv />
      </HasUserRole>
    </Layout>
  );
};

export const Head = () => <title>Eksport CSV | Zapłatomat</title>;

export default exportCsv;
